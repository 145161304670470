import React from 'react';
import { RoomiezContainer, RoomiezWrapper, Box, Flex, Banner, Text, Title, Tag, CenteredText, LeftText } from './roomiez.style';
import RoomiezScreens from '../../../../images/caseStudies/roomiez-background.svg';
import BlueArrow from '../../../../images/caseStudies/blue-arrow.svg';
import Concept from '../../../../images/caseStudies/roomiez-concept.svg';
import Process from '../../../../images/caseStudies/roomiez-process.svg';
import Empathize from '../../../../images/caseStudies/roomiez-empathize.svg';
import Empathize2 from '../../../../images/caseStudies/roomiez-empathize-2.svg';
import Empathize3 from '../../../../images/caseStudies/roomiez-empathize-3.svg';
import Empathize4 from '../../../../images/caseStudies/roomiez-empathize-4.svg';
import Define1 from '../../../../images/caseStudies/roomiez-define.svg';
import Define2 from '../../../../images/caseStudies/roomiez-define-2.svg';
import Define3 from '../../../../images/caseStudies/roomiez-define-3.svg';
import Ideate1 from '../../../../images/caseStudies/roomiez-ideate.svg';
import Test1 from '../../../../images/caseStudies/roomiez-testing.svg';
import Prototype1 from '../../../../images/caseStudies/roomiez-prototype.svg';
import Final1 from '../../../../images/caseStudies/roomiez-final-1.gif';
import Final2 from '../../../../images/caseStudies/roomiez-final-2.gif';
import BicycleLink from '../../../../images/caseStudies/bicycle-link.svg';
import WebsiteLink from '../../../../images/caseStudies/website-link.svg';
import { Links, Link } from '../../Footer/footer.style';
import { navigate } from 'gatsby';
import Linkedin from '../../../../images/caseStudies/linkedin.svg';
import Envelope from '../../../../images/caseStudies/envelope.svg';

export default function Roomiez() {
    return (
        <RoomiezContainer>
            <RoomiezWrapper>
                <Title onClick={()=>navigate('/')}>John Morales</Title>
                <Box style={{textAlign:'right'}}>
                <img style={{ margin: '-59px' }} src={RoomiezScreens} />
                </Box>
                <Box style={{ margin: '0 69px' }}>
                    <CenteredText style={{ margin: '91px 0 65px 0' }}>
                        <Text className='bold' style={{ marginBottom: '12px' }}>About the Project</Text>
                        <Text> This was my initial assignment during the Devmountain program. The project involved developing an iOS application that facilitates connectivity among roommates sharing a household. The app provides a platform for managing chores, authorizing access to shared items, and a payment gateway for transferring funds for rent and other essential expenditures.</Text>
                    </CenteredText>
                    <LeftText>
                        <Text className='bold'>The Problem</Text>
                        <Text>A significant number of individuals who cohabit with roommates encounter difficulties in effectively coordinating household tasks and face challenges in upholding their shared responsibilities. Users have not come across an application that offers the capability to invite roommates or join a household for the purpose of sharing certain or all of their data within their communal living spaces.</Text>
                    </LeftText>
                    <LeftText style={{ marginTop: '89px' }}>
                        <Text className='bold'>Objectives & Goals</Text>
                        <Text>
                        The goal of this product is to <strong>simplify the chore delegation process</strong> and promote a more harmonious and clean living environment. With features such <strong>as creating and assigning tasks, setting deadlines, and tracking progress</strong>, this app will help ensure that everyone contributes their fair share. By making it more manageable to assign and complete chores, this app will help foster a more organized and clean living environment, leading to happier and healthier shared living spaces.
                        </Text>
                    </LeftText>
                    <Box style={{ marginTop: '87px', marginBottom: '116px', display: 'grid', justifyContent: 'center', fontSize: '20px' }}>
                        <Flex style={{ marginBottom: '50px' }}><img loading="lazy" src={BlueArrow} style={{ marginRight: '16px' }} /><Text>Set up a manageable chore system. </Text></Flex>
                        <Flex style={{ marginBottom: '50px' }}><img loading="lazy" src={BlueArrow} style={{ marginRight: '16px' }} /><Text>Divide expenses. </Text></Flex>
                        <Flex style={{ marginBottom: '50px' }}><img loading="lazy" src={BlueArrow} style={{ marginRight: '16px' }} /><Text>Allow access to personal items.</Text></Flex>
                        <Flex><img loading="lazy" src={BlueArrow} style={{ marginRight: '16px' }} /><Text>Push notification reminders.</Text></Flex>
                    </Box>
                    <Flex style={{ marginBottom: '111px' }}>
                        <LeftText style={{ marginRight: 'auto' }}>
                            <Text className='bold-center'>Role</Text>
                            <Text>UX Research</Text>
                            <Text>UI Design</Text>
                            <Text>Wireframing</Text>
                            <Text>Usability Testing</Text>
                            <Text>Prototyping</Text>
                        </LeftText>
                        <LeftText style={{ marginRight: 'auto' }}>
                            <Text className='bold-center'>Platform</Text>
                            <Text>iOS app</Text>
                        </LeftText>
                        <LeftText style={{ marginRight: 'auto' }}>
                            <Text className='bold-center'>Timeline</Text>
                            <Text>April - May 2022</Text>
                        </LeftText>
                        <LeftText style={{ marginRight: 'auto' }}>
                            <Text className='bold-center'>Tools</Text>
                            <Text>Figma, FigJam</Text>
                        </LeftText>
                        <LeftText>
                            <Text className='bold-center'>Team</Text>
                            <Text>Kat Winegar</Text>
                            <Text>Sam Saeedi </Text>
                            <Text>Yumina Yamaguchi</Text>
                        </LeftText>
                    </Flex>
                    <LeftText style={{ marginBottom: '111px' }}>
                        <Text className='bold'>Concept</Text>
                        <Text>This mobile app gives the users the ability to have a <strong>chore system</strong>, where each person can mark off tasks as they complete them and assign tasks to others as needed. Additionally, the app would allow roommates to <strong>borrow items</strong> from each other and keep track of who has what, reducing the risk of lost items. Lastly, the app would have a <strong>payment system</strong>, making it easy for roommates to transfer funds between each other for shared expenses like rent, utilities, and groceries.</Text>
                    </LeftText>
                    <Flex style={{ justifyContent: 'center' }}>
                        <img loading="lazy" src={Concept} style={{ width: '60vw' }} />
                    </Flex>
                    <Text style={{ fontSize: '40px', fontWeight: 'bold', marginTop: '131px', marginBottom: '62px', textAlign: 'center' }}>Our Process</Text>
                    <Box style={{ textAlign: 'center' }}>
                        <img loading="lazy" src={Process} style={{ width: '60vw' }} />
                    </Box>
                </Box>
            </RoomiezWrapper>
            <Banner><Text className='text'>Empathize</Text></Banner>
            <RoomiezWrapper>
                <LeftText>
                    <Text className='bold'>Understanding the User</Text>
                    <Text>In order to gain a deeper understanding of the intended user, a sequence of <strong>user interviews</strong> and <strong>surveys</strong> were undertaken with the primary aim of identifying <strong>key problems</strong> and <strong>challenges</strong> experienced by roommates. This research had a particular emphasis on pinpointing the primary issue and concerns that arise in shared living arrangements.</Text>
                </LeftText>
                <LeftText style={{marginTop:'60px'}}>
                    <Text className='bold'>Interview & Survey Questions </Text>
                    <Text>To help identify the primary areas of concern for the user, we asked a series of questions during the interview and survey procedure. A number of these questions consisted of: </Text>
                </LeftText>
                <Box style={{textAlign: 'center', marginTop: '40px', marginBottom: '110px'}}>
                    <img loading="lazy" src={Empathize} style={{width: '60vw'}}/>
                </Box>
                <Box style={{textAlign: 'center', marginTop: '40px', marginBottom: '110px'}}>
                    <img loading="lazy" src={Empathize4} style={{width: '85vw'}}/>
                </Box>
                <Box style={{textAlign: 'center', marginTop: '40px', marginBottom: '110px'}}>
                    <img loading="lazy" src={Empathize2} style={{width: '85vw'}}/>
                </Box>
                <Box style={{textAlign: 'center', marginTop: '40px', marginBottom: '110px'}}>
                    <img loading="lazy" src={Empathize3} style={{width: '70vw'}}/>
                </Box>
            </RoomiezWrapper>
            <Banner><Text className='text'>Define</Text></Banner>
            <RoomiezWrapper>
                <LeftText>
                    <Text className='bold'>User Persona Creation</Text>
                    <Text>Following an analysis of the data obtained from user interviews and surveys, it was important that the creation of a persona was necessary to facilitate a clear visualization of the <strong>target end-user.</strong></Text>
                    <br/>
                    <Text>To guide the design process effectively, an accurate persona was made representing a college student who has certain pain points and motivations while living in a house with 3 other roommates.</Text>
                </LeftText>
                <Box style={{textAlign: 'center', marginTop: '75px'}}>
                <Box>
                <img loading="lazy" src={Define1} style={{ width: '60vw', marginBottom: '120px' }} />
                </Box>
                <Box>
                <img loading="lazy" src={Define2} style={{ width: '60vw', marginBottom: '120px'}} />
                </Box>
                <Box>
                <img loading="lazy" src={Define3} style={{ width: '60vw', marginBottom: '120px' }} />
                </Box>
                </Box>
            </RoomiezWrapper>
            <Banner><Text className='text'>Ideate</Text></Banner>
            <RoomiezWrapper>
                <LeftText>
                    <Text className='bold'>Sketches</Text>
                    <Text>After finalizing and mutually agreeing on the primary goals and objectives for our users to achieve, we proceeded towards the <strong>ideation phase</strong>, during which each team member created a sketch encompassing their perception of the requirements for every page.</Text>
                </LeftText>
                <Box style={{textAlign: 'center', marginTop: '85px', marginBottom: '85px'}}>
                    <img loading="lazy" src={Ideate1} style={{ width: '80vw' }} />
                </Box>
            </RoomiezWrapper>
            <Banner><Text className='text'>Prototype</Text></Banner>
            <RoomiezWrapper>
                <LeftText>
                    <Text className='bold'>Wireframes & Low-Fidelity Prototype</Text>
                    <Text>Upon gaining a comprehensive understanding of the user's desired tasks, it was time for the design process. Low-fidelity wireframes were developed and prototyped for each of the main pages in Figma. This step was instrumental in the visual structuring of the application, as it enabled us to outline the intended layout of the app with greater clarity.</Text>
                </LeftText>
                <Box style={{textAlign: 'center', width: 'fit-content', margin: '90px auto 79px auto'}}>
                    <img loading="lazy" src={Prototype1} style={{ width: '60vw' }} />
                </Box>
            </RoomiezWrapper>
            <Banner><Text className='text'>Test</Text></Banner>
            <RoomiezWrapper>
                <LeftText>
                    <Text className='bold'>User Testing</Text>
                    <Text>Prior to proceeding with the development of high-fidelity mockups, our team conducted a moderated usability study to test the low-fidelity prototype using 8 total participants. During the study, users were asked to carry out various tasks to see if we can identify issues and errors that could exist.</Text>
                </LeftText>
                <Box style={{textAlign: 'center', marginTop: '110px'}}>
                        <img loading="lazy" src={Test1} style={{ width: '60vw', marginBottom: '110px' }} />
                </Box>
                
            </RoomiezWrapper>
            <Banner><Text className='text'>Final Design</Text></Banner>
            <RoomiezWrapper>
                <LeftText>
                    <Text className='bold'>High Fidelity Wireframe</Text>
                    <Text>After gathering sufficient data from our usability testing, we started on the creation of our high fidelity wireframes. Through collaborative team discussions, we established a design system which served as our guiding framework throughout the final design process. </Text>
                </LeftText>
                <Box style={{textAlign:'center', marginTop:'120px'}}>
                    <img loading="lazy" src={Final1} style={{ width: '25vw' }} />
                </Box>
                <Box style={{textAlign:'center', marginTop:'120px'}}>
                <img loading="lazy" src={Final2} style={{ width: '25vw' }} />
            </Box>
            </RoomiezWrapper>
            <Banner><Text className='text'>Wrapping Up</Text></Banner>
            <RoomiezWrapper>
            <LeftText>
                <Text className='bold'>Takeaways</Text>
                <Text>With this project, I learned the challenges faced by individuals living with roommates. It became evident that many people struggle to stay updated on household matters due to the busy schedules and responsibilities of each individual involved. It allowed me to sharpen my skills in understanding user issues and designing solutions that cater to their needs.</Text>
            </LeftText>
            <LeftText style={{marginTop:'110px'}}>
                <Text className='bold'>Next Steps</Text>
                <Text>When it comes to improving this experience some next steps and goals for this product would be:</Text>
                <br/>
                <Text style={{marginLeft:'20px'}}>• Conduct additional usability testing for both the low-fidelity and high-fidelity prototypes to gather furthermore data for improvements and iterations.</Text>
                <br/>
                <Text style={{marginLeft:'20px'}}>• Add an additional feature - “House Administrator”. It will allow a user access to an elevated privilege and responsibility to oversee various aspects of the living arrangement.</Text>
            </LeftText>
            </RoomiezWrapper>
            <RoomiezWrapper>
            <Text style={{textAlign:'center', fontSize:'36px', marginBottom:'50px', fontFamily: "Sansita" }}>See more of my work</Text>
            <Box style={{textAlign :'center'}}>
                    <img onClick={()=>navigate('/dont-quit-bicycle')} src={BicycleLink} style={{ width: '290px', marginRight:'25px', cursor: 'pointer' }} />
                    <img onClick={()=>navigate('https://www.figma.com/file/9ZCZV5CSuT4JvOZ7vgI7iy/My-website?type=design&node-id=781%3A7289&mode=design&t=cratq7L1vDsqrnmM-1')} src={WebsiteLink} style={{ width: '290px',  cursor: 'pointer' }} />
                </Box>
                <Box style={{border:'1px solid black', marginTop:'11px', marginBottom:'50px'}}></Box>
                <Text style={{textAlign:'center', fontSize:'64px', fontFamily: "Sansita", fontWeight: 700}}>Let's Connect</Text>
                <br/>
                <Links>
                <Link href="https://drive.google.com/file/d/1TAsG1GGcv9ny1YugdZVkOB3nxBe6VgZB/view" target="_blank" style={{color:'black', marginLeft: "0px"}}>
                  Resume
                </Link>
                <Link href='mailto:jay_morales0@yahoo.com' target="_blank" style={{color:'black', display: "flex"}}>
                <img loading="lazy" src={Envelope}  style={{ marginRight: "10px"}}/>
                  Mail
                </Link>
                <Link href='https://www.linkedin.com/in/jay-morales' target="_blank" style={{color:'black', display: "flex"}}>
                <img loading="lazy" src={Linkedin} style={{ marginRight: "10px"}}/>
                  Linkedin
                </Link>
              </Links>
            </RoomiezWrapper>
        </RoomiezContainer>
    );
}
