import styled from 'styled-components';

export const Flex = styled.div`
    display: flex;
`;

export const Title = styled.div`
    font-size: 72px;
    font-family: Sansita;
    font-weight: bold;
    text-shadow: 0px 4px 4px #00000040;
    margin-bottom: 68px;
    cursor: pointer;
`;

export const Tag = styled.div`
    width: 186.22px;
    height: 56.71px;
    border-radius: 51.63px;
    background-color: #F8EDDD;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const CenteredText = styled.div`
    text-align: center;
    font-size: 20px;
    .bold {
        font-weight: bold;
        font-size: 28px;
        margin-bottom: 12px;
    }
`

export const LeftText = styled.div`
    font-size: 20px;
    .bold {
        font-weight: bold;
        font-size: 28px;
        margin-bottom: 18px;
    }
    .bold-center{
        font-weight: bold;
        text-align: center;
        font-size: 24px;
        margin-bottom: 8px;
    }
`

export const Box = styled.div`
`;

export const Text = styled.div`

`;

export const RoomiezWrapper = styled.div`
    margin: 59px;
    font-family: Poppins;
`;

export const RoomiezContainer = styled.div`
`;

  export const Banner = styled.div`
    height: 165px;
    background-color: #778DA9;
    font-family: Poppins;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    .text{
        font-size: 64px;
        font-weight: bold;
    }
`;